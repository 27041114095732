import {
  IWidgetModuleProvider,
  WidgetModuleArgs,
  WidgetModule,
} from './IWidgetModuleProvider';
import { loadBundledCode } from '../utils/importScriptBundledCodeModule';

export class BundledWidgetModuleProvider implements IWidgetModuleProvider {
  private _widgetBundleUrls: Record<string, string>;

  constructor(widgetBundleUrls: Record<string, string>) {
    this._widgetBundleUrls = widgetBundleUrls;
  }

  getModule<T extends object>(widgetType: string) {
    const widgetCodeUrl = this._widgetBundleUrls[widgetType];

    return async (globals: WidgetModuleArgs<T>) => {
      const { init } = await loadBundledCode<WidgetModule<object>>(
        widgetCodeUrl,
      );
      return init(globals);
    };
  }
}
